<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Comboboxes">
                    <template v-slot:description>
                        <div>
                            <div>import { HbChip, HbCombobox }  from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">
            <template v-slot:title>
                Open Form Combobox Examples + Code
                <v-spacer></v-spacer>
                <hb-btn small @click="validateOpenForm('default')">Test Validation</hb-btn>
            </template>

            <hb-form label="Journey 1" description="Select the wizards you want to include on this journey" required full>
                <hb-combobox
                    v-model="combobox1"
                    :items="items"
                    v-validate.disable="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-as="Journey 1"
                    id="journey1"
                    name="journey1"
                    :error="errors.collect('default.journey1').length > 0"
                    label="Select, Search for, or Manually Enter New Wizards"
                >
                </hb-combobox>
            </hb-form>

            <hb-form label="Journey 2" required>

                <hb-combobox
                    v-model="combobox2"
                    :items="items"
                    v-validate.disable="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-as="Journey 2"
                    id="journey2"
                    name="journey2"
                    :error="errors.collect('default.journey2').length > 0"
                    label="Select, Search for, or Manually Enter New Wizards"
                ></hb-combobox>
            </hb-form>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-form label="Journey 1" description="Select the wizards you want to include on this journey" required>
    &lt;hb-combobox
        v-model="combobox1"
        :items="items"
        v-validate.disable="'required|max:45'"
        data-vv-scope="default"
        data-vv-as="Journey 1"
        id="journey1"
        name="journey1"
        :error="errors.collect('default.journey1').length > 0"
        label="Select, Search for, or Manually Enter New Wizards"
    >&lt;/hb-combobox>
&lt;/hb-form>

&lt;hb-form label="Journey 2" required>
    &lt;hb-combobox
        v-model="combobox2"
        :items="items"
        v-validate.disable="'required|max:45'"
        data-vv-scope="default"
        data-vv-as="Journey 2"
        id="journey2"
        name="journey2"
        :error="errors.collect('default.journey2').length > 0"
        label="Select, Search for, or Manually Enter New Wizards"
    >&lt;/hb-combobox>
&lt;/hb-form>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Editable Combobox Form Examples + Code" class="mt-4 mb-6">

            <hb-form label="Journey 3" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="combobox3.length === 0" description="Select the wizards you want to include on this journey">
                <template v-slot:display>
                    <hb-chip v-for="(item, i) in combobox3" :key="'combobox3' + i">
                        {{ item }}
                    </hb-chip>
                </template>
                <template v-slot:edit>
                    <hb-combobox
                        v-model="combobox3Input"
                        :items="items"
                        v-validate.disable="'required|max:45'"
                        data-vv-as="Journey 3"
                        id="journey3"
                        name="journey3"
                        :error="errors.collect('journey3').length > 0"
                        label="Select, Search for, or Manually Enter New Wizards"
                    ></hb-combobox>
                </template>
            </hb-form>

            <hb-form label="Journey 4" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="combobox4.length === 0">
                <template v-slot:display>
                    <hb-chip v-for="(item, i) in combobox4" :key="'combobox4' + i">
                        {{ item }}
                    </hb-chip>
                </template>
                <template v-slot:edit>
                    <hb-combobox
                        v-model="combobox4Input"
                        :items="items"
                        v-validate.disable="'required|max:45'"
                        data-vv-as="Journey 4"
                        id="journey4"
                        name="journey4"
                        :error="errors.collect('journey4').length > 0"
                        label="Select, Search for, or Manually Enter New Wizards"
                    ></hb-combobox>
                </template>
            </hb-form>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-form label="Journey 3" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="combobox3.length === 0">
    &lt;template v-slot:display>
        &lt;hb-chip v-for="(item, i) in combobox3" :key="'combobox3' + i">
            <span v-pre>{{ item }}</span>
        &lt;/hb-chip>
    &lt;/template>
    &lt;template v-slot:edit>
        &lt;hb-combobox
            v-model="combobox3Input"
            :items="items"
            v-validate.disable="'required|max:45'"
            data-vv-as="Journey 3"
            id="journey3"
            name="journey3"
            :error="errors.collect('journey3').length > 0"
            label="Select, Search for, or Manually Enter New Wizards"
            description="Select the wizards you want to include on this journey"
        >&lt;/hb-combobox>
    &lt;/template>
&lt;/hb-form>

&lt;hb-form label="Journey 4" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="combobox4.length === 0">
    &lt;template v-slot:display>
        &lt;hb-chip v-for="(item, i) in combobox4" :key="'combobox4' + i">
            <span v-pre>{{ item }}</span>
        &lt;/hb-chip>
    &lt;/template>
    &lt;template v-slot:edit>
        &lt;hb-combobox
            v-model="combobox4Input"
            :items="items"
            v-validate.disable="'required|max:45'"
            data-vv-as="Journey 4"
            id="journey4"
            name="journey4"
            :error="errors.collect('journey4').length > 0"
            label="Select, Search for, or Manually Enter New Wizards"
        >&lt;/hb-combobox>
    &lt;/template>
&lt;/hb-form>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Select Only Combobox (i.e. autocomplete and manual additions are disabled) + Code" class="mt-4 mb-6">

            <hb-form label="Journey 5" description="Select the wizards you want to include on this journey" required>
                
                <hb-combobox
                    select
                    v-model="combobox5"
                    :items="selectOnlyItems"
                    v-validate.disable="'required|max:45'"
                    data-vv-as="Journey 5"
                    id="journey5"
                    name="journey5"
                    :error="errors.collect('journey5').length > 0"
                    label="Select wizards"
                >
                </hb-combobox>
            </hb-form>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-form label="Journey 5" description="Select the wizards you want to include on this journey" required>
    &lt;hb-combobox
        select
        v-model="combobox5"
        :items="items"
        v-validate.disable="'required|max:45'"
        data-vv-as="Journey 5"
        id="journey5"
        name="journey5"
        :error="errors.collect('journey5').length > 0"
        label="Select wizards"
    >&lt;/hb-combobox>
&lt;/hb-form>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="<hb-combobox> Props, Slots, &amp; Events" class="mb-6 pb-1">
            <hb-data-table
                :headers="vuetifyHeaders"
                :items="vuetifyItems"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <span v-if="item.resource === 'NA'">{{ item.resource }}</span>
                    <hb-link v-else :href="item.resource" target="_blank">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="snackbar"
            :type="type"
            :list="type === 'error' && list ? errors.items : []"
            :description="description"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemComboboxes",
        data: function() {
            return {
                active: '',
                type: '',
                list: false,
                description: '',
                snackbar: false,
                combobox1: [],
                combobox2: ['HarryPotterReallyLongNameOverflow@hogwarts.com','RonWeasley@hogwarts.com'],
                combobox3: [],
                combobox3Input: [],
                combobox4: ['Harry','Ron','Albus'],
                combobox4Input: ['Harry','Ron','Albus'],
                combobox5: ['Harry','Ron'],
                items: ['Harry','Ron','Albus','Severus','Voldemort'],
                selectOnlyItems: ['Harry','Ron','Voldemort'],
                snackbarError: false,
                snackbarSuccess: false,
                vuetifyHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                    { text: 'Resource', value: 'resource' },
                ],
                vuetifyItems: [
                    { name: 'select', type: 'boolean', default: 'false', description: 'If changed to true, this makes the combobox behave as a select only functionality. The ability to autocomplete and manual enter items will be disabled. Design has deemed that this will be used in cases such as when the available item list is 3 or less. Email address items will likely need to have default behavior regardless of the "3" rule because we want to allow the user to ability to manually add email addresses. Reach out to design on a case by case basis on which type of behavior is required.', resource: '' },
                    { name: 'show-select-all', type: 'boolean', default: 'true', description: 'If changed to false, this will turn off the "Select All" option.', resource: '' },
                    { name: 'full', type: 'boolean', default: 'false', description: 'If changed to true, this will attempt to make the combobox be full width instead of the default 370px max width. If used within an HbForm, you must also set the full prop to true for HbForm to allow the input area to go full width as well.', resource: '' },
                    { name: 'all vuetify v-combobox props, slots, and events', type: 'NA', default: 'NA',  description: 'All vuetify "v-combobox" props, slots, and events can be used in an "hb-combobox". The following props and events are hardcoded and unavailable to change (multiple, small-chips, single-line, hide-details, clearable, @click:clear, clear-icon), but that is intentional to maintain visual/functionality uniformity. See the vuetify documentation link in the next column for the list of available props, slots, and events.', resource: 'https://v2.vuetifyjs.com/en/api/v-combobox/' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@click', description: 'Event for when the delete "x" icon is clicked.' },
                ]
            };
        },
        methods: {
            handleEmittedCancel(label){
                switch(label){
                    case 'Journey 3':
                        this.combobox3Input = this.combobox3;
                        break;
                    case 'Journey 4':
                        this.combobox4Input = this.combobox4;
                        break;
                }
                this.list = false;
                this.description = 'The editing of the form row: "' + label +'" was canceled. This "@cancel" action is emitted from it\'s base level custom component. This message is not intended for a normal user to see. It is just here to notify developers of the emitted event.'
                this.type = 'error';
                this.snackbar = true;
            },
            handleEmittedSave(label){
                let param = '';
                switch(label){
                    case 'Journey 3':
                        param = 'journey3';
                        break;
                    case 'Journey 4':
                        param = 'journey4';
                        break;
                }
                this.$validator.validate(param).then(valid => {
                    if (!valid) {
                        this.list = true;
                        this.description = "There are errors in your form, correct them before continuing."
                        this.type = 'error';
                        this.snackbar = true;
                        return;
                    } else {
                        switch(label){
                            case 'Journey 3':
                                this.combobox3 = this.combobox3Input;
                                break;
                            case 'Journey 4':
                                this.combobox4 = this.combobox4Input;
                                break;
                        }
                        this.active = 'saved';
                        this.description = 'The saving of form row: "' + label + '" was successful. This "@save" action is emitted from it\'s base level custom component. This message is not intended for a normal user to see. It is just here to notify developers of the emitted event.'
                        this.type = 'success';
                        this.snackbar = true;
                    }
                });
            },
            validateOpenForm(param){
                this.$errors = '';
                this.$validator.validateAll(param).then(valid => {
                    if (!valid) {
                        this.list = true;
                        this.description = "There are errors in your form, correct them before continuing."
                        this.type = 'error';
                        this.snackbar = true;
                    } else {
                        this.description = "All fields passed validation. This message is not intended for a normal user to see. It is just here to notify developers that the validation was successful."
                        this.type = 'success';
                        this.snackbar = true;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
